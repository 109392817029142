import { Routes } from '@angular/router';

export const contentPvo: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('../../components/home/home.module').then((m) => m.HomeModule),
    data: {
      breadcrumb: 'Uy',
    },
    //   canActivate: [AuthGuard]
  },
  {
    path: 'indexes/:id',
    loadChildren: () =>
      import('../../components/indexes/indexes.module').then(
        (m) => m.IndexesModule
      ),
    data: {
      breadcrumb: 'Indexes',
    },
  },
  {
    path: 'being-checked',
    loadChildren: () =>
      import(
        '../../components/being-checked-pvo/being-checked-pvo.module'
      ).then((m) => m.BeingCheckedPvoModule),
    data: {
      breadcrumb: 'Tekshirilayotgan',
    },
  },
  {
    path: 'rejected-field',
    loadChildren: () =>
      import('../../components/rejected-field/rejected-field.module').then(
        (m) => m.RejectedFieldModule
      ),
    data: {
      breadcrumb: 'Rad etilgan',
    },
  },
  {
    path: 'rating',
    loadChildren: () =>
      import('../../components/rating/rating.module').then(
        (m) => m.RatingModule
      ),
    data: {
      breadcrumb: 'Reyting',
    },
  },
  {
    path: 'indexes-data-pvo/:id',
    loadChildren: () =>
      import('../../components/indexes-data-pvo/indexes-data-pvo.module').then(
        (m) => m.IndexesDataPvoModule
      ),
    data: {
      breadcrumb: "Ma'lumotlar",
    },
  },
  {
    path: 'announce',
    loadChildren: () =>
      import('../../components/announce/announce.module').then(
        (m) => m.AnnounceModule
      ),
    data: {
      breadcrumb: "E'lonlar",
    },
  },
];

export const contentAdmin: Routes = [
  {
    path: 'teachers',
    loadChildren: () =>
      import('../../components/teachers/teachers.module').then(
        (m) => m.TeachersModule
      ),
    data: {
      breadcrumb: 'Foydalanuvchilar',
    },
  },
  {
    path: 'announce-admin',
    loadChildren: () =>
      import('../../components/announce-admin/announce-admin.module').then(
        (m) => m.AnnounceAdminModule
      ),
    data: {
      breadcrumb: "E'lonlar",
    },
  },
  {
    path: 'create-index',
    loadChildren: () =>
      import('../../components/create-index/create-index.module').then(
        (m) => m.CreateIndexModule
      ),
    data: {
      breadcrumb: 'Yaratish',
    },
  },
  {
    path: 'indexes-data/:id',
    loadChildren: () =>
      import('../../components/indexes-data/indexes-data.module').then(
        (m) => m.IndexesDataModule
      ),
    data: {
      breadcrumb: "Ma'lumotlar",
    },
  },
  {
    path: 'ctrl-indexes',
    loadChildren: () =>
      import('../../components/ctrl-indexes/ctrl-indexes.module').then(
        (m) => m.CtrlIndexesModule
      ),
    data: {
      breadcrumb: 'boshqarish',
    },
  },
  {
    path: 'home-admin',
    loadChildren: () =>
      import('../../components/home-admin/home-admin.module').then(
        (m) => m.HomeAdminModule
      ),
    data: {
      breadcrumb: 'Uy',
    },
  },
  {
    path: 'check-ind',
    loadChildren: () =>
      import('../../components/check-ind/check-ind.module').then(
        (m) => m.CheckIndModule
      ),
    data: {
      breadcrumb: 'Tekshirish',
    },
  },
  {
    path: 'check-ind-bkaf/:id',
    loadChildren: () =>
      import('../../components/check-ind-bkaf/check-ind-bkaf.module').then(
        (m) => m.CheckIndBkafModule
      ),
    data: {
      breadcrumb: 'Tekshirish Kaf',
    },
  },
  {
    path: 'teachers',
    loadChildren: () =>
      import('../../components/teachers/teachers.module').then(
        (m) => m.TeachersModule
      ),
    data: {
      breadcrumb: 'O‘qituvchilar',
    },
  },
  {
    path: 'users',
    loadChildren: () =>
      import('../../components/users/users.module').then((m) => m.UsersModule),
    data: {
      breadcrumb: 'Foydalanuvchilar',
    },
  },
  {
    path: 'system/area',
    loadChildren: () =>
      import('../../components/area/area.module').then((m) => m.AreaModule),
    data: {
      breadcrumb: 'Kafedra',
    },
  },
  {
    path: 'system/departments',
    loadChildren: () =>
      import('../../components/departments/departments.module').then(
        (m) => m.DepartmentsModule
      ),
    data: {
      breadcrumb: 'Fakultet',
    },
  },
  {
    path: 'ratinga',
    loadChildren: () =>
      import('../../components/rating/rating.module').then(
        (m) => m.RatingModule
      ),
    data: {
      breadcrumb: 'Reyting',
    },
  },
  {
    path: 'register-new',
    loadChildren: () =>
      import('../../components/rating/rating.module').then(
        (m) => m.RatingModule
      ),
    data: {
      breadcrumb: 'Yangi foydalanuvchi',
    },
  },
];
export const contentTijoratlashtish: Routes = [
  {
    path: 'indexes-data-tij/:id',
    loadChildren: () =>
      import('../../components/indexes-data/indexes-data.module').then(
        (m) => m.IndexesDataModule
      ),
    data: {
      breadcrumb: "Ma'lumotlar",
    },
  },
  {
    path: 'check-ind-tij',
    loadChildren: () =>
      import('../../components/check-ind/check-ind.module').then(
        (m) => m.CheckIndModule
      ),
    data: {
      breadcrumb: 'Tekshirish',
    },
  },
];
// {
//   path: 'dashboard',
//   loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
// },
// {
//   path: 'products',
//   loadChildren: () => import('../../components/products/products.module').then(m => m.ProductsModule),
//   data: {
//     breadcrumb: "Products"
//   }
// },
// {
//   path: 'sales',
//   loadChildren: () => import('../../components/sales/sales.module').then(m => m.SalesModule),
//   data: {
//     breadcrumb: "Sales"
//   }
// },
// {
//   path: 'coupons',
//   loadChildren: () => import('../../components/coupons/coupons.module').then(m => m.CouponsModule),
//   data: {
//     breadcrumb: "Coupons"
//   }
// },
// {
//   path: 'pages',
//   loadChildren: () => import('../../components/pages/pages.module').then(m => m.PagesModule),
//   data: {
//     breadcrumb: "Pages"
//   }
// },
// {
//   path: 'media',
//   loadChildren: () => import('../../components/media/media.module').then(m => m.MediaModule),
// },
// {
//   path: 'menus',
//   loadChildren: () => import('../../components/menus/menus.module').then(m => m.MenusModule),
//   data: {
//     breadcrumb: "Menus"
//   }
// },
// {
//   path: 'users',
//   loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
//   data: {
//     breadcrumb: "Foydalanuvchilar",
//     role: "ADMIN"
//   },
//   canActivate: [AuthGuard]
// },
// {
//   path: 'vendors',
//   loadChildren: () => import('../../components/vendors/vendors.module').then(m => m.VendorsModule),
//   data: {
//     breadcrumb: "Vendors"
//   }
// },
// {
//   path: 'localization',
//   loadChildren: () => import('../../components/localization/localization.module').then(m => m.LocalizationModule),
//   data: {
//     breadcrumb: "Localization"
//   }
// },
// {
//   path: 'reports',
//   loadChildren: () => import('../../components/reports/reports.module').then(m => m.ReportsModule),
// },
// {
//   path: 'settings',
//   loadChildren: () => import('../../components/setting/setting.module').then(m => m.SettingModule),
//   data: {
//     breadcrumb: "Settings"
//   }
// },
// {
//   path: 'invoice',
//   loadChildren: () => import('../../components/invoice/invoice.module').then(m => m.InvoiceModule),
//   data: {
//     breadcrumb: "Invoice"
//   }
// },
// {
//   path: 'create-index',
//   loadChildren: () => import('../../components/create-index/create-index.module').then(m => m.CreateIndexModule),
//   data: {
//     breadcrumb: "Yaratish",
//     role: "ADMIN"
//   },
//   canActivate: [AuthGuard]
// },
