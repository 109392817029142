import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  contentPvo,
  contentAdmin,
  contentTijoratlashtish,
} from './shared/routes/content-routes';
import { ContentLayoutComponent } from './shared/layout/content-layout/content-layout.component';
import { LoginComponent } from './components/auth/login/login.component';
import { AutoLoginGuard } from './guards/auto-login.guard';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: '',
    component: ContentLayoutComponent,
    children: contentPvo,
    data: {
      role: 'PVO',
    },
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: ContentLayoutComponent,
    children: contentAdmin,
    data: {
      role: 'ADMIN',
    },
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: ContentLayoutComponent,
    children: contentTijoratlashtish,
    data: {
      role: 'TIJORATLASHTIRISH',
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'auth/login',
    component: LoginComponent,
    canActivate: [AutoLoginGuard],
  },
  { path: '**', redirectTo: '/home' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
